/* eslint-disable */
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useApi } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum BerthaFundFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    EditionYear = 'EDITION_YEAR',
    FundCountriesOfCoproduction = 'FUND_COUNTRIES_OF_COPRODUCTION',
    FundProductionStatus = 'FUND_PRODUCTION_STATUS',
    FundRequestType = 'FUND_REQUEST_TYPE',
}

export type BerthaFundFilterInput = {
    key?: InputMaybe<BerthaFundFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum DocLabFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    Format = 'FORMAT',
    NewMediaType = 'NEW_MEDIA_TYPE',
    Section = 'SECTION',
    Tags = 'TAGS',
}

export type DocLabFilterInput = {
    key?: InputMaybe<DocLabFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum DocsForSaleFilterEnum {
    Accessibility = 'ACCESSIBILITY',
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    DfsSubject = 'DFS_SUBJECT',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    SalesAgents = 'SALES_AGENTS',
    Section = 'SECTION',
}

export type DocsForSaleFilterInput = {
    key?: InputMaybe<DocsForSaleFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum ForumFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    ForumCountriesOfCoproduction = 'FORUM_COUNTRIES_OF_COPRODUCTION',
    ForumProductionStatus = 'FORUM_PRODUCTION_STATUS',
    ForumRequestType = 'FORUM_REQUEST_TYPE',
    ForumSubject = 'FORUM_SUBJECT',
}

export type ForumFilterInput = {
    key?: InputMaybe<ForumFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum ImageFormatEnum {
    Jpeg = 'JPEG',
    Webp = 'WEBP',
}

export enum ProfessionalsFilterEnum {
    BerthaFund = 'BERTHA_FUND',
    DocsForSale = 'DOCS_FOR_SALE',
    DocLab = 'DOC_LAB',
    Forum = 'FORUM',
    Information = 'INFORMATION',
    ProjectSpace = 'PROJECT_SPACE',
    Story = 'STORY',
}

export enum ProjectSpaceFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    EditionYear = 'EDITION_YEAR',
    ProjectSpaceProductionStatus = 'PROJECT_SPACE_PRODUCTION_STATUS',
    ProjectSpaceRequestType = 'PROJECT_SPACE_REQUEST_TYPE',
}

export type ProjectSpaceFilterInput = {
    key?: InputMaybe<ProjectSpaceFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum TicketAvailabilityStatusEnum {
    HighAvailability = 'HIGH_AVAILABILITY',
    LowAvailability = 'LOW_AVAILABILITY',
    SoldOut = 'SOLD_OUT',
}

export type DocsForSaleCommentInput = {
    filmId: Scalars['String'];
    message: Scalars['String'];
};

export type ProfessionalsFilmViewInput = {
    filmId: Scalars['String'];
};

export type BerthaFundFilmQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type BerthaFundFilmQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        isAlternativeContent?: boolean | null;
        fundingAmount?: string | null;
        lengthInMinutes?: number | null;
        logline?: string | null;
        yearOfProduction?: number | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        countriesOfCoproduction?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
        requestType?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        productionStatus?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        newMediaType?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        } | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    } | null;
};

export type BerthaFundFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type BerthaFundFilmRedirectQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null } | null;
};

export type BerthaFundFilmStepperQueryVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    query?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Array<BerthaFundFilterInput> | BerthaFundFilterInput>;
}>;

export type BerthaFundFilmStepperQuery = {
    __typename?: 'ProfessionalsQuery';
    searchBerthaFund: {
        __typename?: 'SearchBerthaFundResult';
        totalHits: number;
        hits: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }>;
    };
};

export type DocLabFilmQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DocLabFilmQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        isAlternativeContent?: boolean | null;
        lengthInMinutes?: number | null;
        yearOfProduction?: number | null;
        description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            experiences?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            docLabVideo?: { __typename?: 'Video'; id: string; title?: string | null; value: string } | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
            links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        newMediaType?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    } | null;
};

export type DocLabFilmStepperQueryVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    query?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Array<DocLabFilterInput> | DocLabFilterInput>;
}>;

export type DocLabFilmStepperQuery = {
    __typename?: 'ProfessionalsQuery';
    searchDocLab: {
        __typename?: 'SearchDocLabResult';
        totalHits: number;
        hits: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }>;
    };
};

export type DocLabFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DocLabFilmRedirectQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null } | null;
};

export type DocsForSaleFilmQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DocsForSaleFilmQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        lengthInMinutes?: number | null;
        lengths?: string | null;
        yearOfProduction?: number | null;
        logline?: string | null;
        docsForSaleSeries?: string | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
            links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        productionStatus?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        spokenLanguages?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        docsForSaleSynopsisEntry?: {
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        } | null;
        viewingReport?: { __typename?: 'ViewingReport'; pdf: string; csv: string } | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    } | null;
};

export type DocsForSaleFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DocsForSaleFilmRedirectQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null } | null;
};

export type DocsForSaleFilmStepperQueryVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    query?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Array<DocsForSaleFilterInput> | DocsForSaleFilterInput>;
}>;

export type DocsForSaleFilmStepperQuery = {
    __typename?: 'ProfessionalsQuery';
    searchDocsForSale: {
        __typename?: 'SearchDocsForSaleResult';
        totalHits: number;
        hits: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }>;
    };
};

export type ForumFilmQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ForumFilmQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        logline?: string | null;
        startProduction?: string | null;
        monthOfProduction?: number | null;
        yearOfProduction?: number | null;
        startRelease?: string | null;
        lengths?: string | null;
        totalBudget?: number | null;
        financingInPlace?: number | null;
        requestedFinancing?: number | null;
        interestedInFollowingProducers?: string | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        requestType?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        productionStatus?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            forumPerson?: {
                __typename?: 'Person';
                id: string;
                fullName?: string | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
                mainAccreditation?: {
                    __typename?: 'Accreditation';
                    publications?: {
                        __typename?: 'PublicationCollection';
                        favoriteImage?: {
                            __typename?: 'Image';
                            blurHash?: string | null;
                            webp?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                            jpeg?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                        } | null;
                    } | null;
                } | null;
            } | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        countriesOfCoproduction?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        forumSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        forumProjectDescriptionEntry?: {
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        } | null;
        standAloneVersions?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        typeOfNewMediaProject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        financiers?: Array<{ __typename?: 'Financier'; contribution: number; id: string; name: string }> | null;
        forumOpenTalent?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        forumCoprodPotential?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        forumBiography?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        contactPerson?: { __typename?: 'ContactPerson'; email?: string | null; fullName?: string | null } | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    } | null;
    meetingRequest?: { __typename?: 'MyIdfaUser'; meetingRequestLink?: string | null } | null;
};

export type ForumFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ForumFilmRedirectQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null } | null;
};

export type ForumFilmStepperQueryVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    query?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Array<ForumFilterInput> | ForumFilterInput>;
}>;

export type ForumFilmStepperQuery = {
    __typename?: 'ProfessionalsQuery';
    searchForum: {
        __typename?: 'SearchForumResult';
        totalHits: number;
        hits: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }>;
    };
};

export type DefaultEditionFragment = {
    __typename?: 'Edition';
    id: string;
    name: string;
    year: number;
    editionType: { __typename?: 'EditionType'; id: string; description: string };
};

export type MeetingRequestLinkQueryVariables = Exact<{ [key: string]: never }>;

export type MeetingRequestLinkQuery = {
    __typename?: 'ProfessionalsQuery';
    viewer?: { __typename?: 'MyIdfaUser'; meetingRequestLink?: string | null } | null;
};

export type MyAccreditationsQueryVariables = Exact<{ [key: string]: never }>;

export type MyAccreditationsQuery = {
    __typename?: 'ProfessionalsQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        email: string;
        accreditationForms: {
            __typename?: 'FionaFormCollection';
            myEntries?: Array<{
                __typename?: 'FionaFormEntry';
                isSubmitted: boolean;
                form: { __typename?: 'FionaForm'; url: string; title: string };
            }> | null;
            availableForms?: Array<{ __typename?: 'FionaForm'; url: string; title: string }> | null;
        };
    } | null;
};

export type MySubmissionsQueryVariables = Exact<{ [key: string]: never }>;

export type MySubmissionsQuery = {
    __typename?: 'ProfessionalsQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        email: string;
        applicationForms: {
            __typename?: 'FionaFormCollection';
            myEntries?: Array<{
                __typename?: 'FionaFormEntry';
                isSubmitted: boolean;
                form: { __typename?: 'FionaForm'; url: string; title: string };
            }> | null;
            availableForms?: Array<{ __typename?: 'FionaForm'; url: string; title: string }> | null;
        };
    } | null;
};

export type ProjectSpaceFilmQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ProjectSpaceFilmQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        lengthInMinutes?: number | null;
        lengths?: string | null;
        yearOfProduction?: number | null;
        logline?: string | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
            links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        countriesOfCoproduction?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        requestType?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        spokenLanguages?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
        projectSpaceProductionStatus?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
    } | null;
};

export type ProjectSpaceFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ProjectSpaceFilmRedirectQuery = {
    __typename?: 'ProfessionalsQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null } | null;
};

export type ProjectSpaceFilmStepperQueryVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    query?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Array<ProjectSpaceFilterInput> | ProjectSpaceFilterInput>;
}>;

export type ProjectSpaceFilmStepperQuery = {
    __typename?: 'ProfessionalsQuery';
    searchProjectSpace: {
        __typename?: 'SearchProjectSpaceResult';
        totalHits: number;
        hits: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }>;
    };
};

export type SearchBerthaFundFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<BerthaFundFilterInput> | BerthaFundFilterInput>;
}>;

export type SearchBerthaFundFiltersQuery = {
    __typename?: 'ProfessionalsQuery';
    searchBerthaFund: {
        __typename?: 'SearchBerthaFundResult';
        filters: Array<{
            __typename?: 'BerthaFundFilter';
            filter: BerthaFundFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchBerthaFundHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<BerthaFundFilterInput> | BerthaFundFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchBerthaFundHitsQuery = {
    __typename?: 'ProfessionalsQuery';
    searchBerthaFund: {
        __typename?: 'SearchBerthaFundResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Film';
            id: string;
            yearOfProduction?: number | null;
            lengthInMinutes?: number | null;
            fullPreferredTitle?: string | null;
            logline?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            requestType?: Array<{
                __typename?: 'CustomFieldOption';
                sortOrder?: number | null;
                translation?: string | null;
            }> | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }>;
    };
};

export type SearchBerthaFundHitFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    fullPreferredTitle?: string | null;
    logline?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        translation?: string | null;
        key?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
};

export type SearchDocLabFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<DocLabFilterInput> | DocLabFilterInput>;
}>;

export type SearchDocLabFiltersQuery = {
    __typename?: 'ProfessionalsQuery';
    searchDocLab: {
        __typename?: 'SearchDocLabResult';
        filters: Array<{
            __typename?: 'DocLabFilter';
            filter: DocLabFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchDocLabHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<DocLabFilterInput> | DocLabFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchDocLabHitsQuery = {
    __typename?: 'ProfessionalsQuery';
    searchDocLab: {
        __typename?: 'SearchDocLabResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Film';
            id: string;
            yearOfProduction?: number | null;
            lengthInMinutes?: number | null;
            fullPreferredTitle?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            newMediaType?: Array<{
                __typename?: 'CustomFieldOption';
                sortOrder?: number | null;
                translation?: string | null;
            }> | null;
            sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }>;
    };
};

export type SearchDocLabHitFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    fullPreferredTitle?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        translation?: string | null;
        key?: string | null;
    }> | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
};

export type SearchDocsForSaleFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<DocsForSaleFilterInput> | DocsForSaleFilterInput>;
}>;

export type SearchDocsForSaleFiltersQuery = {
    __typename?: 'ProfessionalsQuery';
    searchDocsForSale: {
        __typename?: 'SearchDocsForSaleResult';
        filters: Array<{
            __typename?: 'DocsForSaleFilter';
            filter: DocsForSaleFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchDocsForSaleHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<DocsForSaleFilterInput> | DocsForSaleFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchDocsForSaleHitsQuery = {
    __typename?: 'ProfessionalsQuery';
    searchDocsForSale: {
        __typename?: 'SearchDocsForSaleResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Film';
            id: string;
            fullPreferredTitle?: string | null;
            yearOfProduction?: number | null;
            lengthInMinutes?: number | null;
            docsForSaleSeries?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }>;
    };
};

export type SearchDocsForSaleHitFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    docsForSaleSeries?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        translation?: string | null;
        key?: string | null;
    }> | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
};

export type SearchDocsForSaleViewingHistoryHitsQueryVariables = Exact<{ [key: string]: never }>;

export type SearchDocsForSaleViewingHistoryHitsQuery = {
    __typename?: 'ProfessionalsQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        docsForSaleViewingHistory?: Array<{
            __typename?: 'FilmView';
            viewedAt: string;
            film: {
                __typename?: 'Film';
                id: string;
                fullPreferredTitle?: string | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    stills?: Array<{
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    }> | null;
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
                intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            };
        }> | null;
    } | null;
};

export type SearchDocsForSaleViewingHistoryHitFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
};

export type SearchForumFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<ForumFilterInput> | ForumFilterInput>;
}>;

export type SearchForumFiltersQuery = {
    __typename?: 'ProfessionalsQuery';
    searchForum: {
        __typename?: 'SearchForumResult';
        filters: Array<{
            __typename?: 'ForumFilter';
            filter: ForumFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchForumHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<ForumFilterInput> | ForumFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchForumHitsQuery = {
    __typename?: 'ProfessionalsQuery';
    searchForum: {
        __typename?: 'SearchForumResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Film';
            id: string;
            fullPreferredTitle?: string | null;
            lengthInMinutes?: number | null;
            logline?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }>;
    };
};

export type SearchForumHitFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    lengthInMinutes?: number | null;
    logline?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        translation?: string | null;
        key?: string | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
};

export type SearchPreviewsQueryVariables = Exact<{
    query?: InputMaybe<Scalars['String']>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchPreviewsQuery = {
    __typename?: 'ProfessionalsQuery';
    searchPreviews: {
        __typename: 'SearchPreviewsResult';
        totalHits: number;
        hits?: Array<{
            __typename?: 'Film';
            id: string;
            yearOfProduction?: number | null;
            lengthInMinutes?: number | null;
            fullPreferredTitle?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                films?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
    };
};

export type SearchProjectSpaceFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<ProjectSpaceFilterInput> | ProjectSpaceFilterInput>;
}>;

export type SearchProjectSpaceFiltersQuery = {
    __typename?: 'ProfessionalsQuery';
    searchProjectSpace: {
        __typename?: 'SearchProjectSpaceResult';
        filters: Array<{
            __typename?: 'ProjectSpaceFilter';
            filter: ProjectSpaceFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchProjectSpaceHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<ProjectSpaceFilterInput> | ProjectSpaceFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchProjectSpaceHitsQuery = {
    __typename?: 'ProfessionalsQuery';
    searchProjectSpace: {
        __typename?: 'SearchProjectSpaceResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Film';
            id: string;
            fullPreferredTitle?: string | null;
            logline?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            requestType?: Array<{
                __typename?: 'CustomFieldOption';
                translation?: string | null;
                sortOrder?: number | null;
            }> | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }>;
    };
};

export type SearchProjectSpaceHitFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    logline?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        translation?: string | null;
        sortOrder?: number | null;
    }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        translation?: string | null;
        key?: string | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
};

export type StoreDocsForSaleCommentMutationVariables = Exact<{
    input: DocsForSaleCommentInput;
}>;

export type StoreDocsForSaleCommentMutation = {
    __typename?: 'ProfessionalsMutation';
    storeDocsForSaleComment: { __typename?: 'docsForSaleCommentPayload'; success: boolean };
};

export type StoreFilmViewMutationVariables = Exact<{
    input: ProfessionalsFilmViewInput;
}>;

export type StoreFilmViewMutation = {
    __typename?: 'ProfessionalsMutation';
    storeFilmView: { __typename?: 'professionalsFilmViewPayload'; success: boolean };
};

export type ArchiveFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type BerthaFundFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    fundingAmount?: string | null;
    lengthInMinutes?: number | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultCreditFragment = {
    __typename?: 'Credit';
    fullName?: string | null;
    email?: string | null;
    role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
};

export type DocLabFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    lengthInMinutes?: number | null;
    yearOfProduction?: number | null;
    description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        experiences?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        docLabVideo?: { __typename?: 'Video'; id: string; title?: string | null; value: string } | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocsForSaleFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    docsForSaleSeries?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    docsForSaleSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    viewingReport?: { __typename?: 'ViewingReport'; pdf: string; csv: string } | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocschoolFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    educationLevel?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        teacherMaterial?: Array<{ __typename?: 'Document'; title?: string | null; url: string }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    kijkwijzer?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type FestivalFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    isDocLab?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{
        __typename?: 'EditionSection';
        id: string;
        name?: string | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        items?: Array<
            | {
                  __typename: 'Composition';
                  id: string;
                  fullTitle?: string | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  isDocLab?: boolean | null;
                  yearOfProduction?: number | null;
                  lengthInMinutes?: number | null;
                  id: string;
                  fullPreferredTitle?: string | null;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  credits?: Array<{
                      __typename?: 'Credit';
                      fullName?: string | null;
                      role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  }> | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
        > | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    colour?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    format?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    shows?: Array<{
        __typename?: 'Show';
        fullTitle?: string | null;
        startOn?: string | null;
        endOn?: string | null;
        isOngoingProgram?: boolean | null;
        isVideoOnDemand?: boolean | null;
        location?: string | null;
        ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
        externalSaleLink?: string | null;
        id: string;
        noSale?: boolean | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
        introPaper?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    }> | null;
};

export type FilmCardFilmFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    fullPreferredTitle?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type FilmHitFragment = {
    __typename: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    sortedTitle?: string | null;
    logline?: string | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type ForumFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    logline?: string | null;
    startProduction?: string | null;
    monthOfProduction?: number | null;
    yearOfProduction?: number | null;
    startRelease?: string | null;
    lengths?: string | null;
    totalBudget?: number | null;
    financingInPlace?: number | null;
    requestedFinancing?: number | null;
    interestedInFollowingProducers?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        forumPerson?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    forumSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumProjectDescriptionEntry?: {
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    } | null;
    standAloneVersions?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    typeOfNewMediaProject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    financiers?: Array<{ __typename?: 'Financier'; contribution: number; id: string; name: string }> | null;
    forumOpenTalent?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumCoprodPotential?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumBiography?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    contactPerson?: { __typename?: 'ContactPerson'; email?: string | null; fullName?: string | null } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultApiImageFragment = {
    __typename?: 'Image';
    blurHash?: string | null;
    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
};

export type PageHitFragment = {
    __typename: 'Page';
    id: string;
    title: string;
    pageType: string;
    introText?: string | null;
    url: string;
    breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    thumbnail?: {
        __typename?: 'Image';
        blurHash?: string | null;
        webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    } | null;
};

export type ProjectSpaceFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    projectSpaceProductionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
};

export type UserProfileFragment = {
    __typename?: 'MyIdfaUser';
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    roles?: Array<string> | null;
    person?: {
        __typename?: 'Person';
        mainAccreditation?: {
            __typename?: 'Accreditation';
            badges?: Array<{ __typename?: 'Badge'; id: string; guestbookBadge?: string | null } | null> | null;
        } | null;
    } | null;
    favoriteFilms?: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }> | null;
    calendar: {
        __typename: 'Calendar';
        iCalUrl?: string | null;
        items: Array<
            | { __typename?: 'Appointment' }
            | { __typename: 'Show'; id: string }
            | { __typename?: 'Ticket' }
            | { __typename?: 'VolunteerShift' }
        >;
    };
};

type UserProfileCalendarItem_Appointment_Fragment = { __typename?: 'Appointment' };

type UserProfileCalendarItem_Show_Fragment = { __typename: 'Show'; id: string };

type UserProfileCalendarItem_Ticket_Fragment = { __typename?: 'Ticket' };

type UserProfileCalendarItem_VolunteerShift_Fragment = { __typename?: 'VolunteerShift' };

export type UserProfileCalendarItemFragment =
    | UserProfileCalendarItem_Appointment_Fragment
    | UserProfileCalendarItem_Show_Fragment
    | UserProfileCalendarItem_Ticket_Fragment
    | UserProfileCalendarItem_VolunteerShift_Fragment;

export const DefaultApiImageFragmentDoc = `
    fragment DefaultApiImage on Image {
  webp: transforms(format: WEBP) {
    url
    width
    height
  }
  jpeg: transforms(format: JPEG) {
    url
    width
    height
  }
  blurHash
}
    `;
export const DefaultEditionFragmentDoc = `
    fragment DefaultEdition on Edition {
  id
  name
  year
  editionType {
    id
    description
  }
}
    `;
export const SearchBerthaFundHitFragmentDoc = `
    fragment SearchBerthaFundHit on Film {
  id
  credits {
    fullName
    role {
      translation
      key
    }
  }
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  countriesOfProduction {
    translation
    key
  }
  yearOfProduction
  lengthInMinutes
  fullPreferredTitle
  logline
  requestType {
    sortOrder
    translation
  }
  edition {
    ...DefaultEdition
  }
}
    `;
export const SearchDocLabHitFragmentDoc = `
    fragment SearchDocLabHit on Film {
  id
  credits {
    fullName
    role {
      translation
      key
    }
  }
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  countriesOfProduction {
    translation
    key
  }
  yearOfProduction
  lengthInMinutes
  fullPreferredTitle
  intro {
    translation
  }
  premiere {
    key
    translation
  }
  newMediaType {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
}
    `;
export const SearchDocsForSaleHitFragmentDoc = `
    fragment SearchDocsForSaleHit on Film {
  id
  credits {
    fullName
    role {
      translation
      key
    }
  }
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  countriesOfProduction {
    translation
    key
  }
  fullPreferredTitle
  intro {
    translation
  }
  yearOfProduction
  lengthInMinutes
  sections {
    id
    name
  }
  docsForSaleSeries
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
}
    `;
export const SearchDocsForSaleViewingHistoryHitFragmentDoc = `
    fragment SearchDocsForSaleViewingHistoryHit on Film {
  id
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  fullPreferredTitle
  intro {
    translation
  }
}
    `;
export const SearchForumHitFragmentDoc = `
    fragment SearchForumHit on Film {
  id
  credits {
    fullName
    role {
      translation
      key
    }
  }
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  countriesOfProduction {
    translation
    key
  }
  fullPreferredTitle
  lengthInMinutes
  logline
  edition {
    ...DefaultEdition
  }
}
    `;
export const SearchProjectSpaceHitFragmentDoc = `
    fragment SearchProjectSpaceHit on Film {
  id
  credits {
    fullName
    role {
      translation
      key
    }
  }
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  requestType {
    translation
    sortOrder
  }
  countriesOfProduction {
    translation
    key
  }
  fullPreferredTitle
  logline
  edition {
    ...DefaultEdition
  }
}
    `;
export const DefaultCreditFragmentDoc = `
    fragment DefaultCredit on Credit {
  fullName
  role {
    key
    translation
  }
  email
}
    `;
export const ArchiveFilmDetailsFragmentDoc = `
    fragment ArchiveFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const BerthaFundFilmDetailsFragmentDoc = `
    fragment BerthaFundFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    translation
  }
  fundingAmount
  requestType {
    sortOrder
    translation
  }
  lengthInMinutes
  logline
  yearOfProduction
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocLabFilmDetailsFragmentDoc = `
    fragment DocLabFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  description {
    key
    translation
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    experiences {
      id
      title
      value
    }
    docLabVideo {
      id
      title
      value
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  yearOfProduction
  sections {
    id
    name
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocsForSaleFilmDetailsFragmentDoc = `
    fragment DocsForSaleFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  lengths
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  subject {
    sortOrder
    translation
  }
  logline
  docsForSaleSeries
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  docsForSaleSynopsisEntry {
    key
    translation
  }
  viewingReport {
    pdf
    csv
  }
  tags {
    key
    translation
  }
}
    `;
export const DocschoolFilmDetailsFragmentDoc = `
    fragment DocschoolFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  educationLevel {
    translation(language: $language)
  }
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  isAlternativeContent
  docschoolOneliner {
    key
    translation(language: $language)
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
    teacherMaterial {
      title
      url
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  kijkwijzer {
    sortOrder
    translation(language: $language)
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const FilmHitFragmentDoc = `
    fragment FilmHit on Film {
  __typename
  id
  fullPreferredTitle
  sortedTitle
  docschoolOneliner {
    translation
  }
  logline
  description {
    translation
  }
  intro {
    translation
  }
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const FestivalFilmDetailsFragmentDoc = `
    fragment FestivalFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  isDocLab
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  colour {
    key
    translation
  }
  format {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
    description {
      translation
    }
    items {
      ... on Composition {
        __typename
        id
        fullTitle
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
      ... on Film {
        __typename
        isDocLab
        ...FilmHit
        credits {
          fullName
          role {
            translation
            key
          }
        }
        yearOfProduction
        lengthInMinutes
      }
    }
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  shows {
    fullTitle
    startOn
    endOn
    isOngoingProgram
    isVideoOnDemand
    intro {
      translation
    }
    location
    ticketAvailabilityStatus
    externalSaleLink
    id
    composition {
      fullTitle
    }
    introPaper {
      translation
    }
    audience {
      key
      translation
    }
    accessibility {
      translation
    }
    noSale
  }
}
    `;
export const FilmCardFilmFragmentDoc = `
    fragment FilmCardFilm on Film {
  id
  credits {
    fullName
    role {
      key
    }
  }
  yearOfProduction
  fullPreferredTitle
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const ForumFilmDetailsFragmentDoc = `
    fragment ForumFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    links {
      title
      value
    }
    films {
      id
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  requestType {
    sortOrder
    translation
  }
  productionStatus {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
    forumPerson: person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  logline
  countriesOfCoproduction {
    sortOrder
    translation
  }
  forumSynopsisEntry {
    key
    translation
  }
  forumProjectDescriptionEntry {
    key
    translation
  }
  startProduction
  monthOfProduction
  yearOfProduction
  startRelease
  lengths
  standAloneVersions {
    sortOrder
    translation
  }
  typeOfNewMediaProject {
    sortOrder
    translation
  }
  totalBudget
  financingInPlace
  requestedFinancing
  financiers {
    contribution
    id
    name
  }
  forumOpenTalent {
    key
    translation
  }
  forumCoprodPotential {
    key
    translation
  }
  interestedInFollowingProducers
  forumBiography {
    key
    translation
  }
  contactPerson {
    email
    fullName
  }
  subject {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  tags {
    key
    translation
  }
}
    `;
export const PageHitFragmentDoc = `
    fragment PageHit on Page {
  id
  __typename
  title
  pageType
  introText
  url
  breadcrumbs {
    title
    value
  }
  thumbnail {
    ...DefaultApiImage
  }
}
    `;
export const ProjectSpaceFilmDetailsFragmentDoc = `
    fragment ProjectSpaceFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    sortOrder
    translation
  }
  lengthInMinutes
  lengths
  requestType {
    sortOrder
    translation
  }
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation
  }
  logline
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  tags {
    key
    translation
  }
  projectSpaceProductionStatus {
    sortOrder
    translation
  }
}
    `;
export const UserProfileCalendarItemFragmentDoc = `
    fragment UserProfileCalendarItem on CalendarItem {
  ... on Show {
    __typename
    id
  }
}
    `;
export const UserProfileFragmentDoc = `
    fragment UserProfile on MyIdfaUser {
  firstName
  lastName
  email
  roles
  person {
    mainAccreditation {
      badges {
        id
        guestbookBadge
      }
    }
  }
  favoriteFilms {
    id
    fullPreferredTitle
  }
  calendar {
    ... on Calendar {
      __typename
      iCalUrl
      items {
        ... on CalendarItem {
          ...UserProfileCalendarItem
        }
      }
    }
  }
}
    `;
export const BerthaFundFilmDocument = `
    query berthaFundFilm($id: ID!) {
  film: berthaFundFilm(id: $id) {
    ...BerthaFundFilmDetails
  }
}
    ${BerthaFundFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultCreditFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useBerthaFundFilmQuery = <TData = BerthaFundFilmQuery, TError = unknown>(
    variables: BerthaFundFilmQueryVariables,
    options?: UseQueryOptions<BerthaFundFilmQuery, TError, TData>
) =>
    useQuery<BerthaFundFilmQuery, TError, TData>(
        ['berthaFundFilm', variables],
        useApi<BerthaFundFilmQuery, BerthaFundFilmQueryVariables>(BerthaFundFilmDocument).bind(null, variables),
        options
    );
useBerthaFundFilmQuery.document = BerthaFundFilmDocument;

useBerthaFundFilmQuery.getKey = (variables: BerthaFundFilmQueryVariables) => ['berthaFundFilm', variables];
export const BerthaFundFilmRedirectDocument = `
    query berthaFundFilmRedirect($id: ID!) {
  film: berthaFundFilm(id: $id) {
    fullPreferredTitle
  }
}
    `;
export const useBerthaFundFilmRedirectQuery = <TData = BerthaFundFilmRedirectQuery, TError = unknown>(
    variables: BerthaFundFilmRedirectQueryVariables,
    options?: UseQueryOptions<BerthaFundFilmRedirectQuery, TError, TData>
) =>
    useQuery<BerthaFundFilmRedirectQuery, TError, TData>(
        ['berthaFundFilmRedirect', variables],
        useApi<BerthaFundFilmRedirectQuery, BerthaFundFilmRedirectQueryVariables>(BerthaFundFilmRedirectDocument).bind(
            null,
            variables
        ),
        options
    );
useBerthaFundFilmRedirectQuery.document = BerthaFundFilmRedirectDocument;

useBerthaFundFilmRedirectQuery.getKey = (variables: BerthaFundFilmRedirectQueryVariables) => [
    'berthaFundFilmRedirect',
    variables,
];
export const BerthaFundFilmStepperDocument = `
    query berthaFundFilmStepper($offset: Int!, $limit: Int!, $query: String, $filters: [BerthaFundFilterInput!]) {
  searchBerthaFund(
    query: $query
    limit: $limit
    filters: $filters
    offset: $offset
  ) {
    totalHits
    hits {
      id
      fullPreferredTitle
    }
  }
}
    `;
export const useBerthaFundFilmStepperQuery = <TData = BerthaFundFilmStepperQuery, TError = unknown>(
    variables: BerthaFundFilmStepperQueryVariables,
    options?: UseQueryOptions<BerthaFundFilmStepperQuery, TError, TData>
) =>
    useQuery<BerthaFundFilmStepperQuery, TError, TData>(
        ['berthaFundFilmStepper', variables],
        useApi<BerthaFundFilmStepperQuery, BerthaFundFilmStepperQueryVariables>(BerthaFundFilmStepperDocument).bind(
            null,
            variables
        ),
        options
    );
useBerthaFundFilmStepperQuery.document = BerthaFundFilmStepperDocument;

useBerthaFundFilmStepperQuery.getKey = (variables: BerthaFundFilmStepperQueryVariables) => [
    'berthaFundFilmStepper',
    variables,
];
export const DocLabFilmDocument = `
    query docLabFilm($id: ID!) {
  film: docLabFilm(id: $id) {
    ...DocLabFilmDetails
  }
}
    ${DocLabFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultCreditFragmentDoc}`;
export const useDocLabFilmQuery = <TData = DocLabFilmQuery, TError = unknown>(
    variables: DocLabFilmQueryVariables,
    options?: UseQueryOptions<DocLabFilmQuery, TError, TData>
) =>
    useQuery<DocLabFilmQuery, TError, TData>(
        ['docLabFilm', variables],
        useApi<DocLabFilmQuery, DocLabFilmQueryVariables>(DocLabFilmDocument).bind(null, variables),
        options
    );
useDocLabFilmQuery.document = DocLabFilmDocument;

useDocLabFilmQuery.getKey = (variables: DocLabFilmQueryVariables) => ['docLabFilm', variables];
export const DocLabFilmStepperDocument = `
    query docLabFilmStepper($offset: Int!, $limit: Int!, $query: String, $filters: [DocLabFilterInput!]) {
  searchDocLab(query: $query, limit: $limit, filters: $filters, offset: $offset) {
    totalHits
    hits {
      id
      fullPreferredTitle
    }
  }
}
    `;
export const useDocLabFilmStepperQuery = <TData = DocLabFilmStepperQuery, TError = unknown>(
    variables: DocLabFilmStepperQueryVariables,
    options?: UseQueryOptions<DocLabFilmStepperQuery, TError, TData>
) =>
    useQuery<DocLabFilmStepperQuery, TError, TData>(
        ['docLabFilmStepper', variables],
        useApi<DocLabFilmStepperQuery, DocLabFilmStepperQueryVariables>(DocLabFilmStepperDocument).bind(
            null,
            variables
        ),
        options
    );
useDocLabFilmStepperQuery.document = DocLabFilmStepperDocument;

useDocLabFilmStepperQuery.getKey = (variables: DocLabFilmStepperQueryVariables) => ['docLabFilmStepper', variables];
export const DocLabFilmRedirectDocument = `
    query docLabFilmRedirect($id: ID!) {
  film: docLabFilm(id: $id) {
    fullPreferredTitle
  }
}
    `;
export const useDocLabFilmRedirectQuery = <TData = DocLabFilmRedirectQuery, TError = unknown>(
    variables: DocLabFilmRedirectQueryVariables,
    options?: UseQueryOptions<DocLabFilmRedirectQuery, TError, TData>
) =>
    useQuery<DocLabFilmRedirectQuery, TError, TData>(
        ['docLabFilmRedirect', variables],
        useApi<DocLabFilmRedirectQuery, DocLabFilmRedirectQueryVariables>(DocLabFilmRedirectDocument).bind(
            null,
            variables
        ),
        options
    );
useDocLabFilmRedirectQuery.document = DocLabFilmRedirectDocument;

useDocLabFilmRedirectQuery.getKey = (variables: DocLabFilmRedirectQueryVariables) => ['docLabFilmRedirect', variables];
export const DocsForSaleFilmDocument = `
    query docsForSaleFilm($id: ID!) {
  film: docsForSaleFilm(id: $id) {
    ...DocsForSaleFilmDetails
  }
}
    ${DocsForSaleFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultCreditFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useDocsForSaleFilmQuery = <TData = DocsForSaleFilmQuery, TError = unknown>(
    variables: DocsForSaleFilmQueryVariables,
    options?: UseQueryOptions<DocsForSaleFilmQuery, TError, TData>
) =>
    useQuery<DocsForSaleFilmQuery, TError, TData>(
        ['docsForSaleFilm', variables],
        useApi<DocsForSaleFilmQuery, DocsForSaleFilmQueryVariables>(DocsForSaleFilmDocument).bind(null, variables),
        options
    );
useDocsForSaleFilmQuery.document = DocsForSaleFilmDocument;

useDocsForSaleFilmQuery.getKey = (variables: DocsForSaleFilmQueryVariables) => ['docsForSaleFilm', variables];
export const DocsForSaleFilmRedirectDocument = `
    query docsForSaleFilmRedirect($id: ID!) {
  film: docsForSaleFilm(id: $id) {
    fullPreferredTitle
  }
}
    `;
export const useDocsForSaleFilmRedirectQuery = <TData = DocsForSaleFilmRedirectQuery, TError = unknown>(
    variables: DocsForSaleFilmRedirectQueryVariables,
    options?: UseQueryOptions<DocsForSaleFilmRedirectQuery, TError, TData>
) =>
    useQuery<DocsForSaleFilmRedirectQuery, TError, TData>(
        ['docsForSaleFilmRedirect', variables],
        useApi<DocsForSaleFilmRedirectQuery, DocsForSaleFilmRedirectQueryVariables>(
            DocsForSaleFilmRedirectDocument
        ).bind(null, variables),
        options
    );
useDocsForSaleFilmRedirectQuery.document = DocsForSaleFilmRedirectDocument;

useDocsForSaleFilmRedirectQuery.getKey = (variables: DocsForSaleFilmRedirectQueryVariables) => [
    'docsForSaleFilmRedirect',
    variables,
];
export const DocsForSaleFilmStepperDocument = `
    query docsForSaleFilmStepper($offset: Int!, $limit: Int!, $query: String, $filters: [DocsForSaleFilterInput!]) {
  searchDocsForSale(
    query: $query
    limit: $limit
    filters: $filters
    offset: $offset
  ) {
    totalHits
    hits {
      id
      fullPreferredTitle
    }
  }
}
    `;
export const useDocsForSaleFilmStepperQuery = <TData = DocsForSaleFilmStepperQuery, TError = unknown>(
    variables: DocsForSaleFilmStepperQueryVariables,
    options?: UseQueryOptions<DocsForSaleFilmStepperQuery, TError, TData>
) =>
    useQuery<DocsForSaleFilmStepperQuery, TError, TData>(
        ['docsForSaleFilmStepper', variables],
        useApi<DocsForSaleFilmStepperQuery, DocsForSaleFilmStepperQueryVariables>(DocsForSaleFilmStepperDocument).bind(
            null,
            variables
        ),
        options
    );
useDocsForSaleFilmStepperQuery.document = DocsForSaleFilmStepperDocument;

useDocsForSaleFilmStepperQuery.getKey = (variables: DocsForSaleFilmStepperQueryVariables) => [
    'docsForSaleFilmStepper',
    variables,
];
export const ForumFilmDocument = `
    query forumFilm($id: ID!) {
  film: forumFilm(id: $id) {
    ...ForumFilmDetails
  }
  meetingRequest: viewer {
    meetingRequestLink
  }
}
    ${ForumFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultCreditFragmentDoc}`;
export const useForumFilmQuery = <TData = ForumFilmQuery, TError = unknown>(
    variables: ForumFilmQueryVariables,
    options?: UseQueryOptions<ForumFilmQuery, TError, TData>
) =>
    useQuery<ForumFilmQuery, TError, TData>(
        ['forumFilm', variables],
        useApi<ForumFilmQuery, ForumFilmQueryVariables>(ForumFilmDocument).bind(null, variables),
        options
    );
useForumFilmQuery.document = ForumFilmDocument;

useForumFilmQuery.getKey = (variables: ForumFilmQueryVariables) => ['forumFilm', variables];
export const ForumFilmRedirectDocument = `
    query forumFilmRedirect($id: ID!) {
  film: forumFilm(id: $id) {
    fullPreferredTitle
  }
}
    `;
export const useForumFilmRedirectQuery = <TData = ForumFilmRedirectQuery, TError = unknown>(
    variables: ForumFilmRedirectQueryVariables,
    options?: UseQueryOptions<ForumFilmRedirectQuery, TError, TData>
) =>
    useQuery<ForumFilmRedirectQuery, TError, TData>(
        ['forumFilmRedirect', variables],
        useApi<ForumFilmRedirectQuery, ForumFilmRedirectQueryVariables>(ForumFilmRedirectDocument).bind(
            null,
            variables
        ),
        options
    );
useForumFilmRedirectQuery.document = ForumFilmRedirectDocument;

useForumFilmRedirectQuery.getKey = (variables: ForumFilmRedirectQueryVariables) => ['forumFilmRedirect', variables];
export const ForumFilmStepperDocument = `
    query forumFilmStepper($offset: Int!, $limit: Int!, $query: String, $filters: [ForumFilterInput!]) {
  searchForum(query: $query, limit: $limit, filters: $filters, offset: $offset) {
    totalHits
    hits {
      id
      fullPreferredTitle
    }
  }
}
    `;
export const useForumFilmStepperQuery = <TData = ForumFilmStepperQuery, TError = unknown>(
    variables: ForumFilmStepperQueryVariables,
    options?: UseQueryOptions<ForumFilmStepperQuery, TError, TData>
) =>
    useQuery<ForumFilmStepperQuery, TError, TData>(
        ['forumFilmStepper', variables],
        useApi<ForumFilmStepperQuery, ForumFilmStepperQueryVariables>(ForumFilmStepperDocument).bind(null, variables),
        options
    );
useForumFilmStepperQuery.document = ForumFilmStepperDocument;

useForumFilmStepperQuery.getKey = (variables: ForumFilmStepperQueryVariables) => ['forumFilmStepper', variables];
export const MeetingRequestLinkDocument = `
    query meetingRequestLink {
  viewer {
    meetingRequestLink
  }
}
    `;
export const useMeetingRequestLinkQuery = <TData = MeetingRequestLinkQuery, TError = unknown>(
    variables?: MeetingRequestLinkQueryVariables,
    options?: UseQueryOptions<MeetingRequestLinkQuery, TError, TData>
) =>
    useQuery<MeetingRequestLinkQuery, TError, TData>(
        variables === undefined ? ['meetingRequestLink'] : ['meetingRequestLink', variables],
        useApi<MeetingRequestLinkQuery, MeetingRequestLinkQueryVariables>(MeetingRequestLinkDocument).bind(
            null,
            variables
        ),
        options
    );
useMeetingRequestLinkQuery.document = MeetingRequestLinkDocument;

useMeetingRequestLinkQuery.getKey = (variables?: MeetingRequestLinkQueryVariables) =>
    variables === undefined ? ['meetingRequestLink'] : ['meetingRequestLink', variables];
export const MyAccreditationsDocument = `
    query myAccreditations {
  viewer {
    email
    accreditationForms {
      myEntries {
        isSubmitted
        form {
          url
          title
        }
      }
      availableForms {
        url
        title
      }
    }
  }
}
    `;
export const useMyAccreditationsQuery = <TData = MyAccreditationsQuery, TError = unknown>(
    variables?: MyAccreditationsQueryVariables,
    options?: UseQueryOptions<MyAccreditationsQuery, TError, TData>
) =>
    useQuery<MyAccreditationsQuery, TError, TData>(
        variables === undefined ? ['myAccreditations'] : ['myAccreditations', variables],
        useApi<MyAccreditationsQuery, MyAccreditationsQueryVariables>(MyAccreditationsDocument).bind(null, variables),
        options
    );
useMyAccreditationsQuery.document = MyAccreditationsDocument;

useMyAccreditationsQuery.getKey = (variables?: MyAccreditationsQueryVariables) =>
    variables === undefined ? ['myAccreditations'] : ['myAccreditations', variables];
export const MySubmissionsDocument = `
    query mySubmissions {
  viewer {
    email
    applicationForms {
      myEntries {
        isSubmitted
        form {
          url
          title
        }
      }
      availableForms {
        url
        title
      }
    }
  }
}
    `;
export const useMySubmissionsQuery = <TData = MySubmissionsQuery, TError = unknown>(
    variables?: MySubmissionsQueryVariables,
    options?: UseQueryOptions<MySubmissionsQuery, TError, TData>
) =>
    useQuery<MySubmissionsQuery, TError, TData>(
        variables === undefined ? ['mySubmissions'] : ['mySubmissions', variables],
        useApi<MySubmissionsQuery, MySubmissionsQueryVariables>(MySubmissionsDocument).bind(null, variables),
        options
    );
useMySubmissionsQuery.document = MySubmissionsDocument;

useMySubmissionsQuery.getKey = (variables?: MySubmissionsQueryVariables) =>
    variables === undefined ? ['mySubmissions'] : ['mySubmissions', variables];
export const ProjectSpaceFilmDocument = `
    query projectSpaceFilm($id: ID!) {
  film: projectSpaceFilm(id: $id) {
    ...ProjectSpaceFilmDetails
  }
}
    ${ProjectSpaceFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultCreditFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useProjectSpaceFilmQuery = <TData = ProjectSpaceFilmQuery, TError = unknown>(
    variables: ProjectSpaceFilmQueryVariables,
    options?: UseQueryOptions<ProjectSpaceFilmQuery, TError, TData>
) =>
    useQuery<ProjectSpaceFilmQuery, TError, TData>(
        ['projectSpaceFilm', variables],
        useApi<ProjectSpaceFilmQuery, ProjectSpaceFilmQueryVariables>(ProjectSpaceFilmDocument).bind(null, variables),
        options
    );
useProjectSpaceFilmQuery.document = ProjectSpaceFilmDocument;

useProjectSpaceFilmQuery.getKey = (variables: ProjectSpaceFilmQueryVariables) => ['projectSpaceFilm', variables];
export const ProjectSpaceFilmRedirectDocument = `
    query projectSpaceFilmRedirect($id: ID!) {
  film: projectSpaceFilm(id: $id) {
    fullPreferredTitle
  }
}
    `;
export const useProjectSpaceFilmRedirectQuery = <TData = ProjectSpaceFilmRedirectQuery, TError = unknown>(
    variables: ProjectSpaceFilmRedirectQueryVariables,
    options?: UseQueryOptions<ProjectSpaceFilmRedirectQuery, TError, TData>
) =>
    useQuery<ProjectSpaceFilmRedirectQuery, TError, TData>(
        ['projectSpaceFilmRedirect', variables],
        useApi<ProjectSpaceFilmRedirectQuery, ProjectSpaceFilmRedirectQueryVariables>(
            ProjectSpaceFilmRedirectDocument
        ).bind(null, variables),
        options
    );
useProjectSpaceFilmRedirectQuery.document = ProjectSpaceFilmRedirectDocument;

useProjectSpaceFilmRedirectQuery.getKey = (variables: ProjectSpaceFilmRedirectQueryVariables) => [
    'projectSpaceFilmRedirect',
    variables,
];
export const ProjectSpaceFilmStepperDocument = `
    query projectSpaceFilmStepper($offset: Int!, $limit: Int!, $query: String, $filters: [ProjectSpaceFilterInput!]) {
  searchProjectSpace(
    query: $query
    limit: $limit
    filters: $filters
    offset: $offset
  ) {
    totalHits
    hits {
      id
      fullPreferredTitle
    }
  }
}
    `;
export const useProjectSpaceFilmStepperQuery = <TData = ProjectSpaceFilmStepperQuery, TError = unknown>(
    variables: ProjectSpaceFilmStepperQueryVariables,
    options?: UseQueryOptions<ProjectSpaceFilmStepperQuery, TError, TData>
) =>
    useQuery<ProjectSpaceFilmStepperQuery, TError, TData>(
        ['projectSpaceFilmStepper', variables],
        useApi<ProjectSpaceFilmStepperQuery, ProjectSpaceFilmStepperQueryVariables>(
            ProjectSpaceFilmStepperDocument
        ).bind(null, variables),
        options
    );
useProjectSpaceFilmStepperQuery.document = ProjectSpaceFilmStepperDocument;

useProjectSpaceFilmStepperQuery.getKey = (variables: ProjectSpaceFilmStepperQueryVariables) => [
    'projectSpaceFilmStepper',
    variables,
];
export const SearchBerthaFundFiltersDocument = `
    query searchBerthaFundFilters($filters: [BerthaFundFilterInput!]) {
  searchBerthaFund(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchBerthaFundFiltersQuery = <TData = SearchBerthaFundFiltersQuery, TError = unknown>(
    variables?: SearchBerthaFundFiltersQueryVariables,
    options?: UseQueryOptions<SearchBerthaFundFiltersQuery, TError, TData>
) =>
    useQuery<SearchBerthaFundFiltersQuery, TError, TData>(
        variables === undefined ? ['searchBerthaFundFilters'] : ['searchBerthaFundFilters', variables],
        useApi<SearchBerthaFundFiltersQuery, SearchBerthaFundFiltersQueryVariables>(
            SearchBerthaFundFiltersDocument
        ).bind(null, variables),
        options
    );
useSearchBerthaFundFiltersQuery.document = SearchBerthaFundFiltersDocument;

useSearchBerthaFundFiltersQuery.getKey = (variables?: SearchBerthaFundFiltersQueryVariables) =>
    variables === undefined ? ['searchBerthaFundFilters'] : ['searchBerthaFundFilters', variables];
export const SearchBerthaFundHitsDocument = `
    query searchBerthaFundHits($query: String!, $filters: [BerthaFundFilterInput!], $limit: Int!, $offset: Int!) {
  searchBerthaFund(
    query: $query
    filters: $filters
    limit: $limit
    offset: $offset
  ) {
    totalHits
    hits {
      ...SearchBerthaFundHit
    }
  }
}
    ${SearchBerthaFundHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchBerthaFundHitsQuery = <TData = SearchBerthaFundHitsQuery, TError = unknown>(
    variables: SearchBerthaFundHitsQueryVariables,
    options?: UseQueryOptions<SearchBerthaFundHitsQuery, TError, TData>
) =>
    useQuery<SearchBerthaFundHitsQuery, TError, TData>(
        ['searchBerthaFundHits', variables],
        useApi<SearchBerthaFundHitsQuery, SearchBerthaFundHitsQueryVariables>(SearchBerthaFundHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchBerthaFundHitsQuery.document = SearchBerthaFundHitsDocument;

useSearchBerthaFundHitsQuery.getKey = (variables: SearchBerthaFundHitsQueryVariables) => [
    'searchBerthaFundHits',
    variables,
];
export const SearchDocLabFiltersDocument = `
    query searchDocLabFilters($filters: [DocLabFilterInput!]) {
  searchDocLab(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchDocLabFiltersQuery = <TData = SearchDocLabFiltersQuery, TError = unknown>(
    variables?: SearchDocLabFiltersQueryVariables,
    options?: UseQueryOptions<SearchDocLabFiltersQuery, TError, TData>
) =>
    useQuery<SearchDocLabFiltersQuery, TError, TData>(
        variables === undefined ? ['searchDocLabFilters'] : ['searchDocLabFilters', variables],
        useApi<SearchDocLabFiltersQuery, SearchDocLabFiltersQueryVariables>(SearchDocLabFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchDocLabFiltersQuery.document = SearchDocLabFiltersDocument;

useSearchDocLabFiltersQuery.getKey = (variables?: SearchDocLabFiltersQueryVariables) =>
    variables === undefined ? ['searchDocLabFilters'] : ['searchDocLabFilters', variables];
export const SearchDocLabHitsDocument = `
    query searchDocLabHits($query: String!, $filters: [DocLabFilterInput!], $limit: Int!, $offset: Int!) {
  searchDocLab(query: $query, filters: $filters, limit: $limit, offset: $offset) {
    totalHits
    hits {
      ...SearchDocLabHit
    }
  }
}
    ${SearchDocLabHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchDocLabHitsQuery = <TData = SearchDocLabHitsQuery, TError = unknown>(
    variables: SearchDocLabHitsQueryVariables,
    options?: UseQueryOptions<SearchDocLabHitsQuery, TError, TData>
) =>
    useQuery<SearchDocLabHitsQuery, TError, TData>(
        ['searchDocLabHits', variables],
        useApi<SearchDocLabHitsQuery, SearchDocLabHitsQueryVariables>(SearchDocLabHitsDocument).bind(null, variables),
        options
    );
useSearchDocLabHitsQuery.document = SearchDocLabHitsDocument;

useSearchDocLabHitsQuery.getKey = (variables: SearchDocLabHitsQueryVariables) => ['searchDocLabHits', variables];
export const SearchDocsForSaleFiltersDocument = `
    query searchDocsForSaleFilters($filters: [DocsForSaleFilterInput!]) {
  searchDocsForSale(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchDocsForSaleFiltersQuery = <TData = SearchDocsForSaleFiltersQuery, TError = unknown>(
    variables?: SearchDocsForSaleFiltersQueryVariables,
    options?: UseQueryOptions<SearchDocsForSaleFiltersQuery, TError, TData>
) =>
    useQuery<SearchDocsForSaleFiltersQuery, TError, TData>(
        variables === undefined ? ['searchDocsForSaleFilters'] : ['searchDocsForSaleFilters', variables],
        useApi<SearchDocsForSaleFiltersQuery, SearchDocsForSaleFiltersQueryVariables>(
            SearchDocsForSaleFiltersDocument
        ).bind(null, variables),
        options
    );
useSearchDocsForSaleFiltersQuery.document = SearchDocsForSaleFiltersDocument;

useSearchDocsForSaleFiltersQuery.getKey = (variables?: SearchDocsForSaleFiltersQueryVariables) =>
    variables === undefined ? ['searchDocsForSaleFilters'] : ['searchDocsForSaleFilters', variables];
export const SearchDocsForSaleHitsDocument = `
    query searchDocsForSaleHits($query: String!, $filters: [DocsForSaleFilterInput!], $limit: Int!, $offset: Int!) {
  searchDocsForSale(
    query: $query
    filters: $filters
    limit: $limit
    offset: $offset
  ) {
    totalHits
    hits {
      ...SearchDocsForSaleHit
    }
  }
}
    ${SearchDocsForSaleHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchDocsForSaleHitsQuery = <TData = SearchDocsForSaleHitsQuery, TError = unknown>(
    variables: SearchDocsForSaleHitsQueryVariables,
    options?: UseQueryOptions<SearchDocsForSaleHitsQuery, TError, TData>
) =>
    useQuery<SearchDocsForSaleHitsQuery, TError, TData>(
        ['searchDocsForSaleHits', variables],
        useApi<SearchDocsForSaleHitsQuery, SearchDocsForSaleHitsQueryVariables>(SearchDocsForSaleHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchDocsForSaleHitsQuery.document = SearchDocsForSaleHitsDocument;

useSearchDocsForSaleHitsQuery.getKey = (variables: SearchDocsForSaleHitsQueryVariables) => [
    'searchDocsForSaleHits',
    variables,
];
export const SearchDocsForSaleViewingHistoryHitsDocument = `
    query searchDocsForSaleViewingHistoryHits {
  viewer {
    docsForSaleViewingHistory {
      film {
        ...SearchDocsForSaleViewingHistoryHit
      }
      viewedAt
    }
  }
}
    ${SearchDocsForSaleViewingHistoryHitFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useSearchDocsForSaleViewingHistoryHitsQuery = <
    TData = SearchDocsForSaleViewingHistoryHitsQuery,
    TError = unknown
>(
    variables?: SearchDocsForSaleViewingHistoryHitsQueryVariables,
    options?: UseQueryOptions<SearchDocsForSaleViewingHistoryHitsQuery, TError, TData>
) =>
    useQuery<SearchDocsForSaleViewingHistoryHitsQuery, TError, TData>(
        variables === undefined
            ? ['searchDocsForSaleViewingHistoryHits']
            : ['searchDocsForSaleViewingHistoryHits', variables],
        useApi<SearchDocsForSaleViewingHistoryHitsQuery, SearchDocsForSaleViewingHistoryHitsQueryVariables>(
            SearchDocsForSaleViewingHistoryHitsDocument
        ).bind(null, variables),
        options
    );
useSearchDocsForSaleViewingHistoryHitsQuery.document = SearchDocsForSaleViewingHistoryHitsDocument;

useSearchDocsForSaleViewingHistoryHitsQuery.getKey = (variables?: SearchDocsForSaleViewingHistoryHitsQueryVariables) =>
    variables === undefined
        ? ['searchDocsForSaleViewingHistoryHits']
        : ['searchDocsForSaleViewingHistoryHits', variables];
export const SearchForumFiltersDocument = `
    query searchForumFilters($filters: [ForumFilterInput!]) {
  searchForum(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchForumFiltersQuery = <TData = SearchForumFiltersQuery, TError = unknown>(
    variables?: SearchForumFiltersQueryVariables,
    options?: UseQueryOptions<SearchForumFiltersQuery, TError, TData>
) =>
    useQuery<SearchForumFiltersQuery, TError, TData>(
        variables === undefined ? ['searchForumFilters'] : ['searchForumFilters', variables],
        useApi<SearchForumFiltersQuery, SearchForumFiltersQueryVariables>(SearchForumFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchForumFiltersQuery.document = SearchForumFiltersDocument;

useSearchForumFiltersQuery.getKey = (variables?: SearchForumFiltersQueryVariables) =>
    variables === undefined ? ['searchForumFilters'] : ['searchForumFilters', variables];
export const SearchForumHitsDocument = `
    query searchForumHits($query: String!, $filters: [ForumFilterInput!], $limit: Int!, $offset: Int!) {
  searchForum: searchForum(
    query: $query
    filters: $filters
    limit: $limit
    offset: $offset
  ) {
    totalHits
    hits {
      ...SearchForumHit
    }
  }
}
    ${SearchForumHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchForumHitsQuery = <TData = SearchForumHitsQuery, TError = unknown>(
    variables: SearchForumHitsQueryVariables,
    options?: UseQueryOptions<SearchForumHitsQuery, TError, TData>
) =>
    useQuery<SearchForumHitsQuery, TError, TData>(
        ['searchForumHits', variables],
        useApi<SearchForumHitsQuery, SearchForumHitsQueryVariables>(SearchForumHitsDocument).bind(null, variables),
        options
    );
useSearchForumHitsQuery.document = SearchForumHitsDocument;

useSearchForumHitsQuery.getKey = (variables: SearchForumHitsQueryVariables) => ['searchForumHits', variables];
export const SearchPreviewsDocument = `
    query searchPreviews($query: String, $limit: Int!, $offset: Int!) {
  searchPreviews(query: $query, limit: $limit, offset: $offset) {
    __typename
    totalHits
    hits {
      id
      credits {
        fullName
        role {
          translation
          key
        }
      }
      publications {
        stills {
          ...DefaultApiImage
        }
        films {
          id
          title
          value
        }
        favoriteImage {
          ...DefaultApiImage
        }
      }
      countriesOfProduction {
        translation
        key
      }
      yearOfProduction
      lengthInMinutes
      fullPreferredTitle
      description {
        translation
      }
      edition {
        ...DefaultEdition
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchPreviewsQuery = <TData = SearchPreviewsQuery, TError = unknown>(
    variables: SearchPreviewsQueryVariables,
    options?: UseQueryOptions<SearchPreviewsQuery, TError, TData>
) =>
    useQuery<SearchPreviewsQuery, TError, TData>(
        ['searchPreviews', variables],
        useApi<SearchPreviewsQuery, SearchPreviewsQueryVariables>(SearchPreviewsDocument).bind(null, variables),
        options
    );
useSearchPreviewsQuery.document = SearchPreviewsDocument;

useSearchPreviewsQuery.getKey = (variables: SearchPreviewsQueryVariables) => ['searchPreviews', variables];
export const SearchProjectSpaceFiltersDocument = `
    query searchProjectSpaceFilters($filters: [ProjectSpaceFilterInput!]) {
  searchProjectSpace(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchProjectSpaceFiltersQuery = <TData = SearchProjectSpaceFiltersQuery, TError = unknown>(
    variables?: SearchProjectSpaceFiltersQueryVariables,
    options?: UseQueryOptions<SearchProjectSpaceFiltersQuery, TError, TData>
) =>
    useQuery<SearchProjectSpaceFiltersQuery, TError, TData>(
        variables === undefined ? ['searchProjectSpaceFilters'] : ['searchProjectSpaceFilters', variables],
        useApi<SearchProjectSpaceFiltersQuery, SearchProjectSpaceFiltersQueryVariables>(
            SearchProjectSpaceFiltersDocument
        ).bind(null, variables),
        options
    );
useSearchProjectSpaceFiltersQuery.document = SearchProjectSpaceFiltersDocument;

useSearchProjectSpaceFiltersQuery.getKey = (variables?: SearchProjectSpaceFiltersQueryVariables) =>
    variables === undefined ? ['searchProjectSpaceFilters'] : ['searchProjectSpaceFilters', variables];
export const SearchProjectSpaceHitsDocument = `
    query searchProjectSpaceHits($query: String!, $filters: [ProjectSpaceFilterInput!], $limit: Int!, $offset: Int!) {
  searchProjectSpace(
    query: $query
    filters: $filters
    limit: $limit
    offset: $offset
  ) {
    totalHits
    hits {
      ...SearchProjectSpaceHit
    }
  }
}
    ${SearchProjectSpaceHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchProjectSpaceHitsQuery = <TData = SearchProjectSpaceHitsQuery, TError = unknown>(
    variables: SearchProjectSpaceHitsQueryVariables,
    options?: UseQueryOptions<SearchProjectSpaceHitsQuery, TError, TData>
) =>
    useQuery<SearchProjectSpaceHitsQuery, TError, TData>(
        ['searchProjectSpaceHits', variables],
        useApi<SearchProjectSpaceHitsQuery, SearchProjectSpaceHitsQueryVariables>(SearchProjectSpaceHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchProjectSpaceHitsQuery.document = SearchProjectSpaceHitsDocument;

useSearchProjectSpaceHitsQuery.getKey = (variables: SearchProjectSpaceHitsQueryVariables) => [
    'searchProjectSpaceHits',
    variables,
];
export const StoreDocsForSaleCommentDocument = `
    mutation storeDocsForSaleComment($input: docsForSaleCommentInput!) {
  storeDocsForSaleComment(input: $input) {
    success
  }
}
    `;
export const useStoreDocsForSaleCommentMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        StoreDocsForSaleCommentMutation,
        TError,
        StoreDocsForSaleCommentMutationVariables,
        TContext
    >
) =>
    useMutation<StoreDocsForSaleCommentMutation, TError, StoreDocsForSaleCommentMutationVariables, TContext>(
        ['storeDocsForSaleComment'],
        useApi<StoreDocsForSaleCommentMutation, StoreDocsForSaleCommentMutationVariables>(
            StoreDocsForSaleCommentDocument
        ),
        options
    );
export const StoreFilmViewDocument = `
    mutation storeFilmView($input: professionalsFilmViewInput!) {
  storeFilmView(input: $input) {
    success
  }
}
    `;
export const useStoreFilmViewMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<StoreFilmViewMutation, TError, StoreFilmViewMutationVariables, TContext>
) =>
    useMutation<StoreFilmViewMutation, TError, StoreFilmViewMutationVariables, TContext>(
        ['storeFilmView'],
        useApi<StoreFilmViewMutation, StoreFilmViewMutationVariables>(StoreFilmViewDocument),
        options
    );
